
import { useState, useEffect } from 'react';
import React from "react";
import TagManager from 'react-gtm-module';
import ModalVideo from 'react-modal-video';
import AnchorLink from 'react-anchor-link-smooth-scroll';

// Convert JPG/PNG to WEBP
import ImgNextGen from './components/NextGenImg';

// Slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

//  Modal
import Modal from "react-modal";

// SVGs
import { ReactComponent as MoyParkLogo } from './img/svgs/MoyParkLogo.svg';
import { ReactComponent as LeftClouds } from './img/svgs/left-clouds.svg';
import { ReactComponent as RightClouds } from './img/svgs/right-clouds.svg';
import { ReactComponent as PlayButton } from './img/svgs/play-button.svg';
import { ReactComponent as HeaderShape } from './img/svgs/green-swoop-header.svg';
import { ReactComponent as WhyTop } from './img/svgs/why-top.svg';
import { ReactComponent as HeroBottom } from './img/svgs/hero-bottom.svg';
import { ReactComponent as WhyLockup } from './img/svgs/why-lockup.svg';
import { ReactComponent as FooterShape } from './img/svgs/footer-shape.svg';
import { ReactComponent as LightGreenShrub } from './img/svgs/light-green-shrub.svg';
import { ReactComponent as DarkGreenShrub } from './img/svgs/dark-green-shrub.svg';
import { ReactComponent as DarkTree } from './img/svgs/dark-tree.svg';
import { ReactComponent as VisionBottom } from './img/svgs/vision-bottom.svg';
import { ReactComponent as PillarsTop } from './img/svgs/pillars-top.svg';
import { ReactComponent as CarouselTop } from './img/svgs/carousel-top.svg';

// Images
import MillionWaysPng from './img/million-ways.png';
import MillionWaysWebP from './img/million-ways.webp';
import VideoFallbackPng from './img/video-fallback.jpg';
import VideoFallbackWebP from './img/video-fallback.webp';
import SustainabilityPng from './img/sustainability.png';
import SustainabilityWebP from './img/sustainability.webp';
import YayFor50kJpg from './img/yay-for-50k.jpg';
import YayFor50kWebP from './img/yay-for-50k.webp';
import RoadshowJpg from './img/roadshow.jpg';
import RoadshowWebP from './img/roadshow.webp';
import FoodForGoodJpg from './img/food-for-good.jpg';
import FoodForGoodWebP from './img/food-for-good.webp';
import InvestingJpg from './img/investing.jpg';
import InvestingWebP from './img/investing.webp';

// Videos
import InvestingVideo from './img/investing-for-future.mp4';

// STYLES
import "./css/styles.scss";

// GTM
const tagManagerArgs = {
    gtmId: 'GTM-MGSSH55'
}

TagManager.initialize(tagManagerArgs)

function App() {

  const slickSettings = {
    dots: true,
    slidesToScroll: 1,
    slidesToShow: 3,
    infinite: true,
    centerMode: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  // Modal.setAppElement('.modal-video');

  const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const [isOpen, setOpen] = useState(false);
  // const [isVideoOpen, setVideoOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
    // Add Fixed Class to header on scroll
    const header = document.querySelector('.header');
    position >= 1 ? header.classList.add('fixed') : header.classList.remove('fixed');
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
      // console.log(scrollPosition);
    };
  }, [scrollPosition]);


  // Modal Functions
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }


  return (
    <div className="App">

      <header className="header" id="top">
        <LeftClouds className="left-clouds" />
        <RightClouds className="right-clouds" />
        <div className="grid-container full header--top">
          <nav className="grid-x align-center align-middle text-center">
              <AnchorLink className='cell small-12 medium-shrink' href='#top'><MoyParkLogo className="main-logo" /></AnchorLink>
              <AnchorLink className='cell shrink' offset="150" href='#vision'>Our Vision</AnchorLink>
              <AnchorLink className='cell shrink' offset="150" href='#why'>Our Why</AnchorLink>
              <AnchorLink className='cell shrink' offset="150" href='#pillars'>Our Pillars</AnchorLink>
          </nav>
        </div>
        <div className="grid-container header--content">
          <div className="grid-x align-center">
            <div className="cell small-12 text-center logo">
              <ImgNextGen
                srcWebp={MillionWaysWebP}
                fallback={MillionWaysPng}
                alt="Moy Park - A Million Ways"
              />
            </div>
            <div className="cell small-10 video-holder">
              <ImgNextGen
                srcWebp={VideoFallbackWebP}
                fallback={VideoFallbackPng}
                alt="A Million Ways - Video"
              />
              <PlayButton className="play-button" onClick={() => setOpen(true)} />
            </div>
          </div>
          <div className="shapes">
              <LightGreenShrub className="light-green-shrub" />
              <DarkGreenShrub className="dark-green-shrub" />
              <DarkTree className="tree" />
          </div>
        </div>
        <HeroBottom className="header--shape" />
        <HeaderShape className="header--bottom" />
      </header>

      <section className="container--vision" id="vision">
        <div className="top">
          <div className="image">
              <ImgNextGen
                srcWebp={SustainabilityWebP}
                fallback={SustainabilityPng}
                alt="Moy Park - A Million Ways - Our Vision"
              />
          </div>
          <div className="grid-container copy">
            <div className="grid-x align-right">
              <div className="cell small-12 large-5">
                <h2>This is Our Vision</h2>
                <h4>What is A Million Ways?</h4>
                <p><strong>A Million Ways</strong> is the Moy Park brand and vehicle to both rally and tally the collective effort of the Moy Park community in creating positive societal impact.</p>
                <h4>Why are Moy Park doing it?</h4>
                <p>In line with Moy Park's vision to create the opportunity for a better future for our team members, Moy Park believe there are <strong>A Million Ways</strong> we can all work together for a greater good.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom">
          <VisionBottom className="vision-bottom" />
        </div>
      </section>

      <section className="container--why" id="why">
        <WhyTop className="why-top" />
        <LeftClouds className="left-clouds show-for-large" />
        <RightClouds className="right-clouds show-for-large" />
        <div className="grid-container">
          <div className="grid-x">
            <div className="copy cell small-12 large-5">
              <h2>This is Our Why</h2>
              <p>In 2022 CSR/ESG stands for more and means more than ever before. When we look across Moy Park business and operations we can point to a million little things which add up to make gigantic differences within our workplace, environment, society and community.</p>
              <p><strong>A Million Ways will help us tell this story.</strong></p>
            </div>
          </div>
        </div>
        <div className="why-lockup">
          <WhyLockup  />
        </div>
        
      </section>

      <section className="container--pillars">
        <PillarsTop className="pillars-top" />
        <div className="content">
          <div className="grid-container">
            <div className="grid-x align-center text-center">
              <div className="cell small-12 large-8 heading" id="pillars">
                <h2>These are our Pillars</h2>
                <p>We're making a difference in our workplaces through health and wellbeing initiatives. We're determined to maintain a better, safer work environment for all of our colleagues. We're working to help our environment through commitments to sustainability, animal welfare, biodiversity and reduced water usage. We make a difference in our community through actively supporting charities, schools and universities. and we have a lot more to achieve, with your help.</p>
              </div>
            </div>
            <div className="cell small-12 pillars-boxes">
              <div className="grid-x grid-margin-x grid-margin-y text-center">
                <div className="cell small-12 medium-6 large-3 box">
                  <h4>Workplace</h4>
                  <p>From employee benefits to health and wellbeing safeguards, how Moy Park is working with it's colleagues to create a <strong>better, safer workplace</strong>.</p>
                </div>
                <div className="cell small-12 medium-6 large-3 box">
                  <h4>Environment</h4>
                  <p>The initiatives Moy Park is actively delivering upon to create true and <strong>sustainable environmental impacts</strong> which will benefit us all.</p>
                </div>
                <div className="cell small-12 medium-6 large-3 box">
                  <h4>Community</h4>
                  <p>How Moy Park and it's colleagues are working to benefit <strong>local communities</strong>.</p>
                </div>
                <div className="cell small-12 medium-6 large-3 box">
                  <h4>Social</h4>
                  <p>The Moy Park initiatives in response to <strong>social issues</strong> affecting employees and the wider community.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="shapes--left">
              <DarkTree className="tree--small" />
              <DarkTree className="tree--big" />
          </div>
          <div className="shapes--right">
              <DarkTree className="tree--small" />
              <DarkTree className="tree--big" />
          </div>
          <CarouselTop className="carousel-top" />
        </div>
      </section>

      <div className="container--carousel">
        <div className="grid-container full">
          <div className="grid-x align-center">
              <div className="cell small-12" class="carousel">
                <Slider {...slickSettings}>
                  <div className='carousel--item' onClick={openModal}>
                    <ImgNextGen
                      srcWebp={RoadshowWebP}
                      fallback={RoadshowJpg}
                      alt="Moy Park - A Million Ways - The Moy Park Good Food Roadshow"
                    />
                    <PlayButton className="play-button" />
                    <caption>The Moy Park Good Food Roadshow</caption>
                  </div>
                  <div className='carousel--item'>
                    <ImgNextGen
                      srcWebp={YayFor50kWebP}
                      fallback={YayFor50kJpg}
                      alt="Moy Park - A Million Ways - Yay for 50K!"
                    />
                    <caption>Yay for 50K!</caption>
                  </div>
                  <div className='carousel--item'>
                    <ImgNextGen
                      srcWebp={InvestingWebP}
                      fallback={InvestingJpg}
                      alt="Moy Park - A Million Ways - Investing for the Future"
                    />
                    <caption>Investing for the Future</caption>
                  </div>
                  <div className='carousel--item'>
                    <ImgNextGen
                      srcWebp={FoodForGoodWebP}
                      fallback={FoodForGoodJpg}
                      alt="Moy Park - A Million Ways - Good Food Sports Day"
                    />
                    <caption>Good Food Sports Day</caption>
                  </div>
                </Slider>
              </div>
              <div className="cell small-12 large-10 text-center copy">
                <p>Stay tuned as we get ready to share more <strong>A million ways</strong> we are creating impact as we get ready to embrace 2023.</p>
              </div>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="grid-container">
          <div className="grid-x align-center align-middle text-center">
            <div className="cell small-12">
              <AnchorLink offset="89" href='#vision'>Privacy Policy</AnchorLink>
              <AnchorLink offset="89" href='#why'>Terms &amp; Conditions</AnchorLink>
              <AnchorLink offset="89" href='#pillars'>Contact</AnchorLink>
              <p>Copyright &copy; Moy Park Ltd. 2022. All Rights Reserved.<br/>Food Park, 39 Seagoe Office, Portadown, Craigavon BT63 5QE.</p>
            </div>
          </div>
        </div>
        <FooterShape className="footer-shape" />
      </footer>

      <ModalVideo theme="light" channel='youtube' animationSpeed="500" modestbranding autoplay isOpen={isOpen} videoId="tiOaXgh_9po" onClose={() => setOpen(false)} />
      
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="Example Modal"
        className="modal-video">
          <div className='video-container'>
            <video autoplay muted controls>
              <source src={InvestingVideo} type="video/mp4"></source>
            </video>
            <button onClick={closeModal}></button>
          </div>
      </Modal>

    </div>
  );
}



export default App;
